import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache) {
  const _component_model_tr = _resolveComponent("model-tr")
  const _component_model_attribute_tr = _resolveComponent("model-attribute-tr")
  const _component_model_tree = _resolveComponent("model-tree", true)

  return (_ctx.node != null)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(_component_model_tr, {
          modelValue: _ctx.node,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.node) = $event)),
          onActionEvent: _ctx.actionEvent
        }, null, 8, ["modelValue", "onActionEvent"]),
        (_ctx.node.attributeExpand)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.node.attributeJson, (attribute, index) => {
              return (_openBlock(), _createBlock(_component_model_attribute_tr, {
                key: 'model-attribute-'+_ctx.node.modelCode+'-'+attribute.code+'-'+index,
                modelValue: _ctx.node.attributeJson[index],
                "onUpdate:modelValue": $event => ((_ctx.node.attributeJson[index]) = $event),
                node: _ctx.node,
                index: index,
                onActionEvent: _ctx.actionEvent
              }, null, 8, ["modelValue", "onUpdate:modelValue", "node", "index", "onActionEvent"]))
            }), 128))
          : _createCommentVNode("", true),
        (!_ctx.$tools.isEmpty(_ctx.node.children))
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.node.children, (child, index) => {
              return (_openBlock(), _createBlock(_component_model_tree, {
                key: 'model-tree-'+_ctx.node.modelCode+'-'+child.modelCode+'-'+index,
                modelValue: _ctx.node.children[index],
                "onUpdate:modelValue": $event => ((_ctx.node.children[index]) = $event),
                onActionEvent: _ctx.actionEvent
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onActionEvent"]))
            }), 128))
          : _createCommentVNode("", true)
      ], 64))
    : _createCommentVNode("", true)
}