import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import ProjectModelTableItem from "@/entity/project/ProjectModelTableItem";
import ModelTr from "../model-tr/model-tr.vue";
import ModelAttributeTr from "../model-attribute-tr/model-attribute-tr.vue";
@Options({
    name: "app-project-model-tree",
    components: {
        ModelTree,
        ModelTr,
        ModelAttributeTr,
    },
    props: {
        modelValue: {
            type: [ProjectModelTableItem, Object],
            default: (): any => null,
        },
    },
    emits: ["update:modelValue", "ActionEvent"],
})
export default class ModelTree extends BaseFunction {
    private modelValue!: ProjectModelTableItem;

    get node() {
        return this.modelValue;
    }
    set node(val) {
        this.$emit("update:modelValue", val);
    }
    public actionEvent(action: string, node: ProjectModelTableItem, index: number, event: any) {
        this.$emit("ActionEvent", action, node, index, event);
    }
}
